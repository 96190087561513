
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from './use-auth';
import { Form, Button } from "react-bootstrap";
import setting from "../setting.json";
import { useHistory } from "react-router-dom";

const schema = yup.object().shape({
    email: yup.string().email().required("Enter youer vallid email"),
    password: yup.string()
        .required('No password provided.')
        .min(1, 'Password is too short - should be 1 chars minimum.')
    // .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),

})

const Login = () => {
    let history = useHistory();
    const auth = useAuth();
    const login = (data) => {
        //console.log(auth);
        reset();
        var lg = {
            "email": data.email,
            "password": data.password
        }

        fetch(setting.api + "/api/login",
            {
                body: JSON.stringify(lg),
                method: "post"

            })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    if (data.status === 'success') {
                        // auth.user.userId = data.data.id;
                        // auth.user.role = data.data.role;
                        // auth.user.fname = data.data.fname;
                        // auth.user.lname = data.data.lname;
                        // auth.user.phone = data.data.phone;
                        // auth.user.email = data.data.email;
                        //auth.authorize();
                        var user = {
                            "fname": data.data.fname,
                            "lname": data.data.lname,
                            "token": data.data.token_no
                        }
                        localStorage.setItem("loggedUser", JSON.stringify(user));
                        history.push("/dashboard");
                    }

                }
            )
    }


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    return (
        <>


            <main>
                <div className="container">

                    <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

                                    <div className="d-flex justify-content-center py-4">
                                        <a href="index.html" className="logo d-flex align-items-center w-auto">
                                            <img src="assets/img/logo.png" alt="" />
                                            <span className="d-none d-lg-block">NiceAdmin</span>
                                        </a>
                                    </div>


                                    <div className="card mb-3">

                                        <div className="card-body">

                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                                                <p className="text-center small">Enter your username & password to login</p>
                                            </div>


                                            <Form onSubmit={handleSubmit(login)} className="row g-3 ">
                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email"
                                                            placeholder="name@example.com"
                                                            {...register("email")}


                                                        />
                                                        <p>{errors.email?.message}</p>
                                                    </Form.Group>
                                                </div>


                                                <div className="col-12">
                                                    <Form.Group >
                                                        <Form.Label>Password</Form.Label>
                                                        <Form.Control type="password"
                                                            placeholder="Password"
                                                            {...register("password")}
                                                        />
                                                        <p>{errors.password?.message}</p>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-12">

                                                    <Button className="btn btn-primary w-100" type="submit" >Login</Button>

                                                </div>
                                                <div className="col-12">
                                                    <p className="small mb-0">Don't have account? <a href="pages-register.html">Create an account</a></p>
                                                </div>
                                            </Form>

                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>

                    </section >

                </div >
            </main >

        </>
    );
}
export default Login;
