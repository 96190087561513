
import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Table, ListGroup, Button, Form, Row } from "react-bootstrap";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import setting from "../setting.json";

const schema = yup.object().shape({
    name: yup.string().required("name is required"),
    fullname: yup.string().required("fullname is required"),
});

const Sections = () => {

    const [list, setList] = useState([]);
    const [add, setAdd] = useState([]);
    const [data, setData] = useState([]);

    const [show, setShow] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [updated, setUpdated] = React.useState(false);

    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [fullname, setFullname] = useState('');
    const [rec, setRec] = useState('');


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            name: name,
            fullname: fullname,
        },
        rec
    })

    const saveSection = (data) => {
        let db = new FormData();
        db.append('name', data.name);
        db.append('fullname', data.fullname);
        // console.log(db);
        fetch(setting.api + "/api/addSection",
            {
                body: db,
                method: "post",
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                }
            })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    if (data.status === 'success') {
                        setUpdated(!updated);
                        document.getElementById("name").value = '';
                        document.getElementById("fname").value = '';
                    }

                }
            );
    }



    const getAllSection = async () => {
        await fetch(setting.api + '/api/getAllSection', {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response.json())
            .then(dd => {
                setList(dd.data);

            }
            );

    }

    const getSectionById = () => {
        var id = event.target.getAttribute("data");
        setName('');
        setFullname('');
        setId('');

        setAdd(false);
        data.filter((a) => {
            if (a.id == id) {
                setName(a.name);
                setFullname(a.full_name);
                setId(a.id);
                setRec(a);
                reset(formValues => ({
                    ...formValues,
                    name: a.name,
                    fullname: a.full_name,
                }))


            }

        })
        console.log(name);
    }

    const UpdateSection = async (data) => {
        let db = new FormData();
        db.append('id', data.id);
        db.append('name', data.name);
        db.append('fullname', data.fullname);

        // console.log(db);
        await fetch(setting.api + "/api/updateSection",
            {
                body: db,
                method: "post",
                headers: {
                    'Accept': 'application/FormData',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )

            .then(
                (list) => {
                    getAllSection();
                    document.getElementById("name").value = '';
                    document.getElementById("fname").value = '';
                    document.getElementById("sid").value = '';
                    reset(formValues => ({
                        ...formValues,
                        name: '',
                        fullname: '',
                    }))
                    setAdd(true);
                }
            );


    }


    const deleteSection = async () => {
        var id = event.target.getAttribute("data");
        await fetch(setting.api + '/api/deleteSection?id=' + id, {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            }
        })
            .then(response => response)
            .then(a => {
                getAllSection();
                console.log(a);
            }
            );
    }

    useEffect(() => {

        getAllSection();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);
    useEffect(() => {

        getAllSection();

    }, [updated]);
    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }



    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Section </h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <section>
                    <div className="row">
                        <div className="col-xl-5">
                            {add &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title"> Add section  </div>
                                        <Form onSubmit={handleSubmit(saveSection)}>
                                            <Row>
                                                <Form.Group >
                                                    <Form.Label>Name : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="name"
                                                        {...register("name")}
                                                        placeholder='Enter...'
                                                    />
                                                    {errors.name && <p>{errors.name.message}</p>}
                                                </Form.Group>

                                            </Row>
                                            <Row >
                                                <Form.Group>
                                                    <Form.Label>Full Name : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        id="fname"
                                                        {...register("fullname")}
                                                        placeholder='Enter...'


                                                    />
                                                    {errors.fullname && <p>{errors.fullname.message}</p>}
                                                </Form.Group>
                                            </Row>

                                            <Button type='submit' variant="success" id='sub'>Submit</Button>
                                        </Form>
                                    </div>

                                </div>
                            }

                            {!add &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title"> Edit </div>
                                        <Form onSubmit={handleSubmit(UpdateSection)}>
                                            <Row>
                                                <Form.Group >
                                                    <Form.Label >Name : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        id="name"
                                                        {...register("name")}

                                                    />
                                                    {errors.name && <p>{errors.name.message}</p>}
                                                </Form.Group>

                                            </Row>

                                            <Row >
                                                <Form.Group >
                                                    <Form.Label >Full Name : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        id='fname'
                                                        {...register("fullname")}

                                                    />
                                                    {errors.fullname && <p>{errors.fullname.message}</p>}
                                                </Form.Group>
                                            </Row>
                                            <input type='hidden' id="sid" value={id} {...register("id")} />
                                            <Button type='submit' variant="success" id='sub' >Update</Button>


                                        </Form>
                                    </div>

                                </div>
                            }

                        </div>

                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-title"> Section List  </div>
                                    <Table striped hover variant="">
                                        <thead>
                                            {!data &&
                                                <tr></tr>
                                            }
                                            <tr>
                                                <th>Sr.No.</th>
                                                <th>Name</th>
                                                <th>Full Name</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data && data.map((a, i) =>
                                                    <tr key={a.id}>
                                                        <td>{i + 1}</td>
                                                        <td>{a.name}</td>
                                                        <td>{a.full_name}</td>
                                                        <td>
                                                            <Button variant='primary' className="bi bi-pencil-square" size="sm" data={a.id} onClick={() => getSectionById()}>
                                                            </Button>  {' '}

                                                            <Button variant='danger' className='ri-delete-bin-line' size='sm' data={a.id} onClick={() => deleteSection()}> </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            }

                                        </tbody>
                                    </Table>
                                    <div className='page' id='sub'>

                                        <Button variant='primary' className='btn' size="sm" onClick={clickFirst}>First</Button>{' '}
                                        <Button variant='primary' className='btn' size="sm" onClick={clickPrev}>Prev</Button>
                                        <span >page {currentPage} of {pageCount}</span>
                                        <Button variant='primary' className='btn' size="sm" onClick={clickNext}>Next</Button>{' '}
                                        <Button variant='primary' className='btn' size="sm" onClick={clickLast}>Last</Button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}
export default Sections;