import { Link } from "react-router-dom/cjs/react-router-dom.min"


const Sidebar = () => {
    return (

        < aside id="sidebar" className="sidebar" >

            <ul className="sidebar-nav" id="sidebar-nav">

                <li className="nav-item">
                    <a className="nav-link " href="/">
                        <i className="bi bi-grid"></i>
                        <span>Dashboard</span>
                    </a>
                </li>{ /* <!-- End Dashboard Nav --> */}
                <li className="nav-item">

                    <a className="nav-link collapsed" data-bs-target="#master-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"></i><span>Master</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="master-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">


                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/class">
                                <i className="bi bi-grid"></i>
                                <span>Class</span>
                            </a>
                        </li>{ /* <!-- End class  --> */}


                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/sections">
                                <i className="bi bi-grid"></i>
                                <span>Section</span>
                            </a>
                        </li>{ /* <!-- End seaction  --> */}


                        <li className="nav-item">
                            <a className="nav-link collapsed" href="/academic">
                                <i className="bi bi-grid"></i>
                                <span>Academic</span>
                            </a>
                        </li>{ /* <!-- End academic  --> */}


                        <li>
                            <Link to="/subject">
                                <i className="bi bi-circle"></i><span>Subject</span>
                            </Link>
                        </li>


                    </ul>
                </li>{ /* <!-- End master Nav -->*/}

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"></i><span>Users</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="components-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/users">
                                <i className="bi bi-circle"></i><span>User</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/adduser">
                                <i className="bi bi-circle"></i><span>Add User</span>
                            </Link>
                        </li>
                    </ul>
                </li>{ /* <!-- End users  --> */}

                <li className="nav-item">
                    <a className="nav-link collapsed" data-bs-target="#component-nav" data-bs-toggle="collapse" href="#">
                        <i className="bi bi-menu-button-wide"></i><span>Students</span><i className="bi bi-chevron-down ms-auto"></i>
                    </a>
                    <ul id="component-nav" className="nav-content collapse " data-bs-parent="#sidebar-nav">
                        <li>
                            <Link to="/addstudent">
                                <i className="bi bi-circle"></i><span>Add Students</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admitstudent">
                                <i className="bi bi-circle"></i><span>AdmitStudent</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/currentadmissionlist">
                                <i className="bi bi-circle"></i><span>Admission List</span>
                            </Link>
                        </li>
                    </ul>
                </li>{ /* <!-- End users  --> */}

                <li className="nav-item">
                    <a className="nav-link collapsed" href="/school">
                        <i className="bi bi-menu-button-wide"></i>
                        <span>School Profile</span>
                    </a>
                </li>

                {/* <li className="nav-item">
                    <a className="nav-link collapsed" href="/login">
                        <i className="bi bi-box-arrow-in-right"></i>
                        <span>Login</span>
                    </a>
                </li> */}

                { /* <!-- End Blank Page Nav --> */}
            </ul>
        </aside >

    );
}
export default Sidebar;