
import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, Row, Button, Table, Col } from 'react-bootstrap';
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useState, useEffect } from 'react';
import { computeHeadingLevel } from "@testing-library/react";
import setting from "../setting.json";
const schema = yup.object().shape({
    start: yup.string().required("start_date is required"),
    end: yup.string().required("end_date is required"),
    adm: yup.string().required("please Check box"),
});

const Academic = () => {
    const [add, setAdd] = useState([]);
    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [Updated, setUpdated] = useState(false);

    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const [id, setId] = useState('');
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [adm, setAdm] = useState('');

    const [rec, setRec] = useState('');


    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            start: start,
            end: end,
            adm: adm,
        },
        rec
    })

    const saveAcademic = (data) => {
        let ay = new FormData();
        ay.append('start_date', data.start);
        ay.append('end_date', data.end);
        ay.append('adm_open', data.adm);

        fetch(setting.api + "/api/addAcademicYear",
            {
                body: ay,
                method: "post",
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    if (data.status === 'success') {
                    }
                    setUpdated(!Updated);
                    reset({
                        start: '',
                        end: '',
                        adm: '',

                    })

                }
            );
    }
    const getAllAcademic = async () => {
        await fetch(setting.api + "/api/getAllAcademicYear", {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(al => {
                setList(al.data);

            }
            );

    }

    const getAcademicById = (e) => {
        var idd = e.target.getAttribute("data");

        setAdd(false);

        data.filter((ay) => {
            if (ay.id == idd) {
                setStart(ay.start_date);
                setEnd(ay.end_date);
                setAdm(ay.adm_open);
                setId(ay.id);
                setRec(ay);
                reset({
                    start: ay.start_date,
                    end: ay.end_date,
                    adm: ay.adm_open,

                })

            }

        })
        console.log(id);
    }

    const UpdateAcademic = async (data) => {
        let ay = new FormData();
        ay.append('id', data.id);
        ay.append('start_date', data.start);
        ay.append('end_date', data.end);
        ay.append('adm_open', data.adm);

        console.log(ay);
        await fetch(setting.api + "/api/updateAcademicYear",
            {
                body: ay,
                method: "post",
                headers: {
                    'Accept': 'application/FormData',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )

            .then(
                (list) => {
                    getAllAcademic();
                    reset(formValues => ({
                        ...formValues,
                        start: '',
                        end: '',
                        adm: '',

                    }))
                    setAdd(true);
                }
            );
    }

    const deleteAcademic = async (e) => {
        var id = e.target.getAttribute("data");
        //console.log(e.target);
        await fetch(setting.api + "/api/deleteAcademicYear?id=" + id, {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(
                response => response.json()
            )
            .then(ay => {
                getAllAcademic();
                console.log(ay);
            })
    }


    useEffect(() => {
        // console.log('test');
        getAllAcademic();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);
    useEffect(() => {
        // console.log('test');
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }
    // console.log(adm);
    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }
    return (
        <>
            <Header />
            <Sidebar />

            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Academic</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">pages</li>
                        </ol>
                    </nav>
                </div>

                <section className="section">
                    <div className="row">


                        <section>
                            <div className="row">
                                <Col sm={4}>
                                    {add &&

                                        <div className="card">
                                            <div className="card-body">
                                                <div className='card-title'>Add Academic</div>
                                                <Form onSubmit={handleSubmit(saveAcademic)}>
                                                    <Row>
                                                        <Form.Group >
                                                            <Form.Label >Start Date : </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                placeholder='Enter...'
                                                                {...register("start")}
                                                                id="start"

                                                            />
                                                            {errors.start && <p>{errors.start.message}</p>}
                                                        </Form.Group>
                                                    </Row>
                                                    <Row >
                                                        <Form.Group >
                                                            <Form.Label>End Date : </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                placeholder='Enter...'
                                                                {...register("end")}
                                                                id="end"

                                                            />
                                                            {errors.end && <p>{errors.end.message}</p>}
                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <Form.Group >

                                                            <Form.Label >Admition Open : </Form.Label>
                                                            <div className="mb-3"

                                                                id="adm">

                                                                <Form.Check
                                                                    {...register("adm")}
                                                                    inline
                                                                    label="Yes"
                                                                    type="radio"
                                                                    value={1}
                                                                />
                                                                <Form.Check
                                                                    {...register("adm")}
                                                                    inline
                                                                    label="No"
                                                                    type="radio"
                                                                    value={0}
                                                                />

                                                            </div>

                                                        </Form.Group >
                                                    </Row>

                                                    <Button type='submit' id="sub" variant="success" >Submit</Button>

                                                </Form>
                                            </div>

                                        </div>
                                    }

                                    {!add &&
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="card-title"> Edit </div>
                                                <Form onSubmit={handleSubmit(UpdateAcademic)}>
                                                    <Row>
                                                        <Form.Group >
                                                            <Form.Label >Start Date : </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                placeholder='Enter...'
                                                                {...register("start")}
                                                                id="start"

                                                            />

                                                        </Form.Group>
                                                    </Row>
                                                    <Row >
                                                        <Form.Group >
                                                            <Form.Label>End Date : </Form.Label>
                                                            <Form.Control
                                                                type="date"
                                                                placeholder='Enter...'
                                                                {...register("end")}
                                                                id="end"

                                                            />

                                                        </Form.Group>
                                                    </Row>
                                                    <Row>
                                                        <Form.Group >
                                                            <div className="mb-3"
                                                                id="adm"
                                                            >
                                                                <Form.Check
                                                                    {...register("adm")}
                                                                    inline
                                                                    label="Yes"
                                                                    type="radio"
                                                                    value={1}

                                                                />
                                                                <Form.Check
                                                                    {...register("adm")}
                                                                    inline
                                                                    label="No"
                                                                    type="radio"
                                                                    value={0}
                                                                />

                                                            </div>
                                                        </Form.Group >
                                                    </Row>
                                                    <input type='hidden' id="aid" value={id} {...register("id")} />
                                                    <Button type="submit" variant="success" id='sub' >Update</Button>


                                                </Form>
                                            </div>

                                        </div>
                                    }
                                </Col>
                                <Col sm={8}>

                                    <div className="card">
                                        <div className="card-body">
                                            <div className='card-title'>Academic List</div>
                                            <Table striped hover variant="">
                                                <thead>
                                                    {!data &&
                                                        <tr></tr>
                                                    }
                                                    <tr>
                                                        <th>Sr.No.</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th>Admission</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data && data.map((ay, i) =>
                                                            <tr key={ay.id}>
                                                                <td>{i + 1}</td>
                                                                <td>{ay.start_date}</td>
                                                                <td>{ay.end_date}</td>
                                                                <td>{ay.admission_open == 1 ? 'Open' : 'Close'}</td>
                                                                <td>
                                                                    <Button variant='primary' className="bi bi-pencil-square" size="sm" data={ay.id} onClick={(e) => getAcademicById(e)}></Button>
                                                                    {' '}
                                                                    <Button variant='danger' className='ri-delete-bin-line' size="sm" data={ay.id} onClick={(e) => deleteAcademic(e)}></Button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </Table>
                                            <div className='page' id='sub'>

                                                <Button variant='primary' className='btn' size="sm" onClick={clickFirst}>First</Button>{' '}
                                                <Button variant='primary' className='btn' size="sm" onClick={clickPrev}>Prev</Button>
                                                <span >page {currentPage} of {pageCount}</span>
                                                <Button variant='primary' className='btn' size="sm" onClick={clickNext}>Next</Button>{' '}
                                                <Button variant='primary' className='btn' size="sm" onClick={clickLast}>Last</Button>

                                            </div>
                                        </div>
                                    </div>
                                </Col>

                            </div>
                        </section>


                    </div>
                </section >

            </main >
            <Footer />
        </>
    );

}
export default Academic;