import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import setting from '../setting.json';
import { useParams } from 'react-router-dom';
import { Container, Navbar, Row, Form, Button, Col, Card } from "react-bootstrap";

const schema = yup.object().shape({
    firstname: yup.string().required("Fill value"),
    name: yup.string(),
    lastname: yup.string(),

    ffirstname: yup.string().required("Fill value"),
    fname: yup.string(),
    flastname: yup.string(),

    mfirstname: yup.string().required("Fill value"),
    mname: yup.string(),
    mlastname: yup.string(),

    dob: yup.date()
        .required("please select date"),
    registration: yup.date()
        .required("please select date"),

    physical: yup.string().required("please select any value"),
    gender: yup.string().required("Please select any one option"),

    admission: yup.string()
        .required("Please select any one option"),

    submitted: yup.string()
        .required("Please select any one option"),

    blood: yup.string(),

    addher: yup.number().required("Fill value"),

    mobile: yup.number().required("Please Enter mobile number"),
    altmobile: yup.number(),

    email: yup.string().email().required("Enter youer vallid email"),

    category: yup.string()
        .required("Please select any one option"),

    relegious: yup.string()
        .required("Please select any one option"),
    nationality: yup.string()
        .required("Please select any one option"),
    address: yup.string().required("Fill value"),

    city: yup.string().required("Please Enter youer City"),
    occupation: yup.string().required("Please Enter youer occupation"),
    state: yup.string().required("Please Enter youer state"),
    country: yup.string().required("Please Enter youer country"),

    pincode: yup.string(),

});

const EditStudent = () => {

    const [idd, setIdd] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const { id } = useParams();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const UpdateStudent = async (data) => {
        let ss = {
            "id": id,
            "admission_mode": data.admission,
            "tc_submitted": data.submitted,
            "blood_group": data.blood,
            "stu_fname": data.firstname,
            "stu_mname": data.name,
            "stu_lname": data.lastname,
            "stu_father_fname": data.ffirstname,
            "stu_father_mname": data.fname,
            "stu_father_lname": data.flastname,
            "stu_mother_fname": data.mfirstname,
            "stu_mother_mname": data.mname,
            "stu_mother_lname": data.mlastname,
            "occupation": data.occupation,
            "stu_dob": data.dob,
            "stu_email": data.email,
            "stu_adhar_no": data.addher,
            // "stu_district": data.district,
            "stu_cast": data.category,
            "registration_date": data.registration,
            "stu_gender": data.gender,
            "stu_phone": data.mobile,
            "stu_alternative_num": data.altmobile,
            "stu_relegious": data.relegious,
            "stu_nationality": data.nationality,
            "stu_city": data.city,
            "stu_state": data.state,
            "stu_country": data.country,
            // "stu_permanent_add": data.address,
            "stu_pincode": data.pincode,
            "handicap": data.physical,

        }
        await fetch(setting.api + "/api/updateStudentDetails",
            {
                body: JSON.stringify(ss),
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(response => response.json())
            .then((rec) => console.log(rec));

    }

    const getStudentById = async () => {
        await fetch(setting.api + "/api/getStudentById?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(
                response => response.json()
            )
            .then(
                (us) => {
                    setIdd(us.data.id);
                    reset({
                        "admission": us.data.admission_mode,
                        "submitted": us.data.tc_submitted,
                        "blood": us.data.blood_group,
                        "firstname": us.data.stu_fname,
                        "name": us.data.stu_mname,
                        "lastname": us.data.stu_lname,
                        "ffirstname": us.data.stu_father_fname,
                        "fname": us.data.stu_father_mname,
                        "flastname": us.data.stu_father_lname,
                        "mfirstname": us.data.stu_mother_fname,
                        "mname": us.data.stu_mother_mname,
                        "mname": us.data.stu_mother_mname,
                        "mlastname": us.data.stu_mother_lname,
                        "occupation": us.data.occupation,
                        "dob": us.data.stu_dob,
                        "email": us.data.stu_email,
                        "addher": us.data.stu_adhar_no,
                        "category": us.data.stu_cast,
                        "registration": us.data.registration_date,
                        "gender": us.data.stu_gender,
                        "mobile": us.data.stu_phone,
                        "altmobile": us.data.stu_alternative_num,
                        "relegious": us.data.stu_relegious,
                        "nationality": us.data.stu_nationality,
                        "city": us.data.stu_city,
                        "state": us.data.stu_state,
                        "country": us.data.stu_country,
                        "pincode": us.data.stu_pincode,
                        "physical": us.data.handicap,

                    },
                    )
                }
            );
    }

    const getCountryMaster = async () => {
        await fetch(setting.api + "/api/getCountryMaster", {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sd => {
                setCountryList(sd.data);

            }
            );

    }
    const getStateByCountryId = async (e) => {
        var id = e.target.value;
        console.log(id);
        await fetch(setting.api + "/api/getStateByCountryId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sm => {
                setStateList(sm.data);
            });
    }

    const getCityByStateId = async (e) => {
        var id = e.target.value;
        await fetch(setting.api + "/api/getCityByStateId?id=" + id, {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(cm => {
                setCityList(cm.data);
            });
    }

    useEffect(() => {
        getCountryMaster();
        getStudentById();
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-9">
                                <h3>Edit Student Form</h3>
                            </div>
                            <div className="col-sm-3">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Student enroll</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <Card body>
                    <Card title="title"></Card>
                    <Form onSubmit={handleSubmit(UpdateStudent)}>
                        <Row>
                            <Col>
                                <Form.Group >
                                    <Form.Label>Date Of Admission : </Form.Label>
                                    <Form.Control
                                        type="date" size="sm"
                                        disabled=""
                                        {...register("admission")}

                                    />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group >
                                    <Form.Label>Admission mode : </Form.Label>
                                    <Form.Select className="form-control custom-select"
                                        name="admission_mode" size="sm"
                                        {...register("admission")} >
                                        <option value="1">Direct</option>
                                        <option value="2">Counselling</option>
                                    </Form.Select>
                                    {errors.admission && <p>{errors.admission.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label >TC Submitted</Form.Label>
                                    <Form.Select aria-label="Default select example" size="sm"

                                        {...register("submitted")}
                                    >
                                        <option>--select--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>

                                    </Form.Select>
                                    {errors.submitted && <p>{errors.submitted.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label> Registration Date : </Form.Label>
                                    <Form.Control
                                        type="date" size="sm"
                                        disabled=""
                                        {...register("registration")}

                                    />
                                    {errors.registration && <p>{errors.registration.message}</p>}
                                </Form.Group>

                            </Col>
                        </Row>

                        <Row style={{ marginTop: '10px' }}>
                            <Col >
                                <Form.Group>
                                    <Form.Label >Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Control type="text" className="name" size="sm"
                                    {...register("firstname")}

                                />
                                {errors.firstname && <p>{errors.firstname.message}</p>}
                            </Col>
                            <Col style={{ marginBottom: '10px' }}>
                                <Form.Control type="text" size="sm"
                                    name="stu_father_mname"
                                    {...register("name")}

                                />
                                {errors.name && <p>{errors.name.message}</p>}
                            </Col>
                            <Col>
                                <Form.Control type="text" name="stu_lname" size="sm"
                                    {...register("lastname")}


                                />
                                {errors.lastname && <p>{errors.lastname.message}</p>}
                            </Col>

                        </Row>


                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Father Name</Form.Label>

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    name="stu_father_fname"
                                    {...register("ffirstname")}

                                />
                                {errors.ffirstname && <p>{errors.ffirstname.message}</p>}
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    name="stu_father_mname"
                                    {...register("fname")}

                                />
                                <p>{errors.Father?.message}</p>
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    name="stu_father_lname"
                                    {...register("flastname")}

                                />
                                {errors.flastname && <p>{errors.flastname.message}</p>}
                            </Col>

                        </div>
                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Mother Name</Form.Label>

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    name="stu_mother_fname"
                                    {...register("mfirstname")}

                                />
                                {errors.Mothername && <p>{errors.Mothername.message}</p>}
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    name="stu_mother_mname"
                                    {...register("mname")}

                                />
                                {errors.Mothername && <p>{errors.Mothername.message}</p>}
                            </Col>
                            <Col>
                                <Form.Control type="text" size="sm"
                                    {...register("mlastname")}

                                    name="stu_mother_lname"
                                />
                                {errors.Mothername && <p>{errors.Mothername.message}</p>}
                            </Col>

                        </div>

                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Date of Birth</Form.Label>
                                    <Form.Control type="date"
                                        name="stu_dob" size="sm"
                                        {...register("dob")}

                                    />
                                    {errors.date && <p>{errors.date.message}</p>}
                                </Form.Group>
                            </Col>



                            <Col>
                                <Form.Group>
                                    <Form.Label >Physical Handicaped ?</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        {...register("physical")} size="sm">
                                        <option>--select--</option>
                                        <option value="1">Yes</option>
                                        <option value="2">No</option>

                                    </Form.Select>
                                    {errors.Physicalhandicaped && <p>{errors.Physicalhandicaped.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >Gender</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        {...register("gender")} size="sm"
                                    >
                                        <option>--Select--</option>
                                        <option value="female">Female</option>
                                        <option value="male">Male</option>
                                        <option value="other">Other</option>
                                    </Form.Select>
                                    {errors.gender && <p>{errors.gender.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label >Aaddhar No.</Form.Label>
                                    <Form.Control type="text" name="stu_adhar_no" size="sm" pattern="[0-9]{12}"
                                        {...register("addher")}
                                    />
                                    {errors.Aaddhar && <p>{errors.Aaddhar.message}</p>}
                                </Form.Group>
                            </Col>
                        </div>

                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Blood Group</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        {...register("blood")} size="sm"
                                    >
                                        <option>--Select--</option>
                                        <option>Not Applicable</option>
                                        <option value="A+">A+</option>
                                        <option value="A-">A-</option>
                                        <option value="B+">B+</option>
                                        <option value="b-">B-</option>
                                        <option value="AB+">AB+</option>
                                        <option value="AB-">AB-</option>
                                        <option value="O+">O+</option>
                                        <option value="O-">O-</option>
                                    </Form.Select>
                                    {errors.BloodGroup && <p>{errors.BloodGroup.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Mobile No.</Form.Label>
                                    <Form.Control type="num" name="stu_phone" pattern="[0-9]{10}" size="sm"
                                        {...register("mobile")}
                                    />
                                    {errors.mobile && <p>{errors.mobile.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label >Alternate Mob No. </Form.Label>
                                    <Form.Control type="phone" className="form-control" name="stu_alternative_num" size="sm" pattern="[0-9]{10}"
                                        {...register("altmobile")}

                                    />
                                    {errors.altmobile && <p>{errors.altmobile.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >Email Id.</Form.Label>
                                    <Form.Control type="email" name="stu_email" size="sm" {...register("email")}
                                    />
                                    {errors.email && <p>{errors.email.message}</p>}
                                </Form.Group>
                            </Col>
                        </div>

                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Category</Form.Label>
                                    <Form.Select aria-label="Default select example" name="category" size="sm"
                                        {...register("category")}
                                    >
                                        <option value={1}>--Select--</option>
                                        <option value={2}>General</option>
                                        <option value={3}>SC</option>
                                        <option value={4}>ST</option>
                                        <option value={5}>OBC</option>
                                        <option>GEN-EWS</option>
                                    </Form.Select>
                                    {errors.category && <p>{errors.category.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >Religion</Form.Label>

                                    <Form.Select aria-label="Default select example" name="stu_relegious" size="sm"
                                        {...register("relegious")}
                                    >
                                        <option>--Select--</option>
                                        <option value={1}>Hindu</option>
                                        <option value={2}>Muslim</option>
                                        <option value={3}>Sikh</option>
                                        <option value={4}>Christian</option>
                                        <option value={5}>Other</option>
                                    </Form.Select>
                                    {errors.Religion && <p>{errors.Religion.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >Nationality</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        name="stu_nationality" size="sm"
                                        {...register("nationality")}
                                    >
                                        <option value={1}>--Select--</option>
                                        <option value={2}>Indian</option>
                                        <option value={3}>Nepalese</option>
                                        <option>Other</option>
                                    </Form.Select>
                                    {errors.nationality && <p>{errors.nationality.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >Occupation</Form.Label>
                                    <Form.Control type="text"
                                        name="occupation" size="sm"
                                        {...register("occupation")}
                                    />
                                    {errors.occupation && <p>{errors.occupation.message}</p>}
                                </Form.Group>
                            </Col>
                        </div>

                        <div className="row">
                            <Col>

                                <Form.Group>
                                    <Form.Label>Country</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        {...register('country')} onChange={(e) => getStateByCountryId(e)} >
                                        {
                                            countryList && countryList.map((sd) =>
                                                <option key={sd.id} value={sd.id} >{sd.name}</option>
                                            )
                                        }

                                    </Form.Select>
                                    {errors.country && <p>{errors.country.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label >State</Form.Label>
                                    <Form.Select aria-label="Default select example"
                                        {...register('state')}
                                        onChange={(e) => getCityByStateId(e)}
                                    >
                                        {
                                            stateList && stateList.map((sm) =>
                                                <option key={sm.id} value={sm.id} >{sm.state_name}</option>

                                            )
                                        }


                                    </Form.Select>
                                    {errors.state && <p>{errors.state.message}</p>}
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group>
                                    <Form.Label>City</Form.Label>
                                    <Form.Select aria-label="Default select example" {...register('city')}>
                                        {
                                            cityList && cityList.map((cm) =>
                                                <option key={cm.id} value={cm.id}>{cm.city_name}</option>
                                            )
                                        }

                                    </Form.Select>
                                    {errors.city && <p>{errors.city.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label >Pin Code</Form.Label>
                                    <Form.Control type="text" name="stu_permanent_pin" size="sm" pattern="[0-9]{6}"
                                        {...register("pincode")}
                                    />
                                    {errors.pincode && <p>{errors.pincode.message}</p>}
                                </Form.Group>
                            </Col>

                        </div>

                        <div className="row">
                            <Col>
                                <Form.Group>
                                    <Form.Label >Communication Address</Form.Label>
                                    <Form.Control type="text" name="stu_permanent_add"
                                        {...register('address')} size="sm"
                                    />
                                    {errors.address && <p>{errors.address.message}</p>}
                                </Form.Group>
                            </Col>
                        </div>
                        <input type='hidden' id="sid" value={idd} {...register("id")} />
                        <Button type="submit" variant="primary" id="sub">Update</Button>

                    </Form>
                </Card>

            </main >

            <Footer />

        </>
    );
}
export default EditStudent;