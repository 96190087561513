import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Card, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import setting from "../setting.json";
import { Link } from "react-router-dom";
import React from "react";

const AdmissionList = () => {

    const [list, setList] = useState([]);
    const [add, setAdd] = useState([]);
    const [data, setData] = useState([]);

    const [show, setShow] = useState([]);
    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [updated, setUpdated] = React.useState(false);

    const getAllStudent = async () => {
        await fetch(setting.api + '/api/getAllStudent', {
            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(as => {
                setList(as.data);

            }
            );
        console.log(list);
    }




    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);
    useEffect(() => {

        getAllStudent();

    }, [updated]);
    useEffect(() => {

        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }





    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Student List</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">Home</li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>

                <Card >
                    <Table striped hover variant="">
                        <thead>
                            <tr>
                                <th>Sr.No.</th>
                                <th>Name</th>
                                <th>Father</th>
                                <th>Mother</th>
                                <th>Addhar</th>
                                <th>DOB</th>
                                <th>Class</th>
                                <th>Section</th>
                                <th>Mobile</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data && data.map((a, i) =>
                                    <tr key={a.ID}>
                                        <td>{i + 1}</td>
                                        <td>{a.stu_fname + ' ' + a.stu_mname + ' ' + a.stu_lname}</td>
                                        <td>{a.stu_father_fname + ' ' + a.stu_father_mname + ' ' + a.stu_father_lname}</td>
                                        <td>{a.stu_mother_fname + ' ' + a.stu_mother_mname + ' ' + a.stu_mother_lname}</td>
                                        <td>{a.stu_adhar_no}</td>
                                        <td>{a.stu_dob}</td>
                                        <td>{a.cname}</td>
                                        <td>{a.sname}</td>
                                        <td>{a.stu_phone}</td>
                                        <td>
                                            <Link to={`/editStudent/${a.ID}`}>
                                                <Button variant='primary' className="bi bi-pencil-square" size="sm" >
                                                </Button>
                                            </Link>
                                        </td>

                                    </tr>
                                )
                            }

                        </tbody>
                    </Table>
                    <div className="page" >

                        <Button variant='primary' size="sm" onClick={clickFirst}>First</Button>{' '}
                        <Button variant='primary' size="sm" onClick={clickPrev}>Prev</Button>
                        <span >page {currentPage} of {pageCount}</span>
                        <Button variant='primary' size="sm" onClick={clickNext}>Next</Button>{' '}
                        <Button variant='primary' size="sm" onClick={clickLast}>Last</Button>

                    </div>
                </Card>
            </main >
            <Footer />
        </>
    )
}
export default AdmissionList;