const Footer = () => {
    return (

        <footer id="footer" className="footer">
            <div className="copyright">
                &copy; Copyright <strong><span>NiceAdmin</span></strong>. All Rights Reserved
            </div>
            <div className="credits">

                Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
            <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
        </footer>









    );
}
export default Footer; 