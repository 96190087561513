import { ListGroup, Button, Table } from 'react-bootstrap';
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useState, useEffect } from 'react';
import setting from "../setting.json";

const User = () => {
    const [data, setData] = useState([]);

    const [newsCount, setNewsCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [list, setList] = useState([]);
    const [pending, setPending] = useState(true);


    const getAllUser = async () => {
        await fetch(setting.api + '/api/getAllUser', {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(ur => {
                setList(ur.data);
                setPending(false);
            }
            );
    }

    const deleteUser = async () => {
        var id = event.target.getAttribute("data");
        await fetch(setting.api + '/api/deleteUser?id=' + id, {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response)
            .then(u => {
                getAllUser();
                console.log(u);
            }
            );
    }

    useEffect(() => {
        // console.log('test');
        getAllUser();
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, []);
    useEffect(() => {
        console.log('test');
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [list]);

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);
    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);
    useEffect(() => {
        setNewsCount(list.length);

    }, [list]);

    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }

    return (
        <>
            <Header />
            <Sidebar />

            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>User</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">pages</li>

                        </ol>
                    </nav>
                </div>

                <div>
                    <div className="row">
                        <div className="card">
                            <div className="card-body">
                                <div className='card-title'></div>
                                <Table responsive="sm" striped hover variant="" >
                                    <thead>
                                        <tr>
                                            <th>Sr.No. </th>
                                            <th>Name</th>
                                            {/* <th>List Name</th> */}
                                            <th>Email</th>
                                            <th>Phone </th>
                                            <th>Image </th>
                                            <th>Action </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data && data.map((u, i) =>
                                                <tr key={u.id}>
                                                    <td>{i + 1}</td>
                                                    <td>{u.fname + ' ' + u.lname}</td>
                                                    {/* <td>{u.lname}</td> */}
                                                    <td>{u.email}</td>
                                                    <td>{u.phone}</td>
                                                    <td><img src={u.image} id='photo' /></td>
                                                    <td>

                                                        <Link to={`/Edit/${u.id}`}>
                                                            <Button variant='primary' className="bi bi-pencil-square" size="sm"
                                                            >
                                                            </Button>
                                                        </Link>
                                                        {' '}

                                                        <Button variant='danger' className='ri-delete-bin-line' size='sm' data={u.id} onClick={deleteUser}> </Button>
                                                        {' '}
                                                        <Link to={`/view/${u.id}`}>
                                                            <Button variant='primary' className="bi bi-eye-fill" size="sm"
                                                            >
                                                            </Button>
                                                        </Link>
                                                    </td>

                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </Table>
                                <div className='page' id='sub'>

                                    <Button variant='primary' size="sm" onClick={clickFirst}>First</Button>{' '}
                                    <Button variant='primary' size="sm" onClick={clickPrev}>Prev</Button>
                                    <span >page {currentPage} of {pageCount}</span>
                                    <Button variant='primary' size="sm" onClick={clickNext}>Next</Button>{' '}
                                    <Button variant='primary' size="sm" onClick={clickLast}>Last</Button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
            <Footer />
        </>
    );

}
export default User;