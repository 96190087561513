
import React from "react";


import { Table, ListGroup, Button, Form, Row } from "react-bootstrap";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import setting from "../setting.json";


const Demo = () => {

  const [list, setList] = useState([]);
  const [add, setAdd] = useState([]);
  const [data, setData] = useState([]);

  const [show, setShow] = useState([]);
  const [newsCount, setNewsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [updated, setUpdated] = React.useState(false);

  // const [id, setId] = useState('');
  // const [name, setName] = useState('');
  // const [fullname, setFullname] = useState('');
  // const [rec, setRec] = useState('');




  useEffect(() => {

    setCurrentPage(1);
    setPageCount(Math.ceil((list.length) / pageSize));
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, []);
  useEffect(() => {



  }, [updated]);
  useEffect(() => {

    setCurrentPage(1);
    setPageCount(Math.ceil((list.length) / pageSize));
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, [list]);

  useEffect(() => {
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, [currentPage]);
  useEffect(() => {
    setNewsCount(list.length);

  }, [list]);
  const clickNext = () => {
    if (currentPage === pageCount) {
      setCurrentPage(pageCount);
    } else {
      setCurrentPage(currentPage + 1);
    }
  }

  useEffect(() => {
    var start = ((currentPage - 1) * pageSize);
    var end = (start + pageSize);
    setData(list.slice(start, end));

  }, [currentPage]);
  useEffect(() => {
    setNewsCount(list.length);

  }, [list]);

  const clickPrev = () => {
    if (currentPage === 1) {
      setCurrentPage(1);
    } else {
      setCurrentPage(currentPage - 1);
    }

  }

  const clickFirst = () => {
    setCurrentPage(1);
  }
  const clickLast = () => {
    setCurrentPage(pageCount);
  }



  return (
    <>
      <Header />
      <Sidebar />
      <main id="main" className="main">

        <div className="pagetitle">
          <h1>Section </h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item"><a href="index.html">Home</a></li>
              <li className="breadcrumb-item active">Data</li>
            </ol>
          </nav>
        </div>
        <section>
          <div className="row">
            <div className="col-xl-5">



            </div>

            <div className="col-lg-7">
              <div className="card">
                <div className="card-body">
                  <div className="card-title"> Section List  </div>

                  <div className='page' id='sub'>

                    <Button variant='primary' className='btn' size="sm" onClick={clickFirst}>First</Button>{' '}
                    <Button variant='primary' className='btn' size="sm" onClick={clickPrev}>Prev</Button>
                    <span >page {currentPage} of {pageCount}</span>
                    <Button variant='primary' className='btn' size="sm" onClick={clickNext}>Next</Button>{' '}
                    <Button variant='primary' className='btn' size="sm" onClick={clickLast}>Last</Button>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
export default Demo;