import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Form, Container, Row, Button, Col, } from 'react-bootstrap';
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import setting from "../setting.json";

const schema = yup.object().shape({
    firstname: yup.string().required("Fill value"),
    lastname: yup.string().required("Fill value"),
    email: yup.string().email().required("Enter youer vallid email"),
    password: yup.string()
        .required('No password provided.')
        .min(8, 'Password is too short - should be 8 chars minimum.')
        .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
    phone: yup.number().required("Phone number is not valid"),

})

const Edituser = () => {

    const [idd, setIdd] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [image, setImage] = useState('');

    const { id } = useParams();

    const [rec, setRec] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            fname: firstname,
            lname: lastname,
            email: email,
            phone: phone,
            password: password,

        },
        rec
    })

    const updateUser = async (data) => {
        let au = new FormData();
        au.append('id', data.idd);
        au.append('fname', data.firstname);
        au.append('lname', data.lastname);
        au.append('email', data.email);
        au.append('phone', data.phone);
        au.append('password', data.password);

        // console.log(au);
        fetch(setting.api + "/api/updateUser",
            {
                body: au,
                method: "post",
                headers: {
                    'Accept': 'application/FormData',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )

            .then(
                (list) => {
                    // console.log(list);
                    setIdd('');
                    setRec();
                    reset(formValues => ({
                        ...formValues,
                        firstname: '',
                        lastname: '',
                        email: '',
                        password: '',
                        phone: '',

                    }))


                }
            );
    }


    const getUser = async () => {
        //  console.log(id);
        await fetch(setting.api + "/api/getUser?id=" + id, {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(
                response => response.json()
            )
            .then(
                (au) => {
                    // console.log(au);
                    setIdd(au.data.id);

                    setLastname();
                    setEmail();
                    setPhone();
                    setPassword();

                    reset({

                        firstname: au.data.fname,
                        lastname: au.data.lname,
                        email: au.data.email,
                        phone: au.data.phone,
                        password: au.data.password,

                    })

                }
            );
    }
    useEffect(() => {
        getUser();

    }, []);

    return (
        <>
            <Header />
            <Sidebar />

            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Update User</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">pages</li>
                        </ol>
                    </nav>
                </div>

                <section>
                    <Container>
                        <div className="card">
                            <div className='card-body'>
                                <Form onSubmit={handleSubmit(updateUser)}>
                                    <Row>
                                        <div className='card-title'></div>
                                        <Col>

                                            <Row>
                                                <Form.Group >
                                                    <Form.Label>First Name : </Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder='Enter...'
                                                        {...register("firstname")}
                                                        id="firstname"

                                                    />
                                                    <p>{errors.firstname?.message}</p>
                                                </Form.Group>

                                            </Row>
                                            <Row >
                                                <Form.Group >
                                                    <Form.Label>Last Name : </Form.Label>
                                                    <Form.Control type="text"
                                                        placeholder='Enter...'
                                                        {...register("lastname")}
                                                        id="lastname"

                                                    />
                                                </Form.Group>

                                            </Row>
                                            <Row >
                                                <Form.Group>
                                                    <Form.Label>Email : </Form.Label>
                                                    <Form.Control type="mail"
                                                        placeholder='Enter...'
                                                        {...register("email")}
                                                        id="email"

                                                    />

                                                </Form.Group>

                                            </Row>

                                        </Col>

                                        <Col>
                                            <Row>
                                                <Form.Group >
                                                    <Form.Label>Phone : </Form.Label>
                                                    <Form.Control
                                                        type="number"
                                                        placeholder='Enter...'
                                                        {...register("phone")}
                                                        id="phone"

                                                    />

                                                </Form.Group>

                                            </Row>
                                            <Row>
                                                <Form.Group className='sub'>
                                                    <Form.Label>Password : </Form.Label>
                                                    <Form.Control type="password"
                                                        placeholder='Enter...'
                                                        {...register("password")}
                                                        id="password"

                                                    />

                                                </Form.Group>
                                            </Row>
                                            {/* <Row>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Image : </Form.Label>
                                                    <Form.Control
                                                        type="file"
                                                        {...register("image")}
                                                        id="image"
                                                        multiple

                                                    />
                                                </Form.Group>
                                            </Row> */}
                                            <input type='hidden' id="sid" value={idd} {...register("idd")} />
                                            <Button type='submit' variant="success" id='sub'>Update</Button>

                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </div>
                    </Container>

                </section>


            </main>
            <Footer />
        </>
    );

}
export default Edituser;