
import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';


const userAtom = atomWithStorage("loggedUser", {});
const loggedInAtom = atomWithStorage("isLoggedIn", false);


const useAuth = () => {
    const [user, setUser] = useAtom(userAtom);
    const [isLoggedIn, setIsLoggedIn] = useAtom(loggedInAtom);
    return {
        user,
        isLoggedIn,
        authorize() {
            setIsLoggedIn(true)
            setUser(user);
        },
        unauthorize() {
            setIsLoggedIn(false)
            setUser({});
        }

    }
}

export default useAuth;