
import './App.css';

import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import AddUser from './pages/Adduser';
import user from './pages/User';
import Viewuser from './pages/Viewuser';
import Edituser from './pages/Edituser';
import Sections from './pages/Sections';
import Class from './pages/Class';
import Academic from './pages/Academic';
import Subject from './pages/Subject';
import School from './pages/School';
import AddStudents from './pages/AddStudents';
import AdmitStudent from './pages/AdmitSudent';
import AdmissionList from './pages/AdmissionList';
import EditStudent from './pages/EditStudent';
import Demo from './pages/Demo';


function App() {
  return (
    <Router>
      <Route path="/" exact component={Login} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/sections" exact component={Sections} />
      <Route path="/adduser" exact component={AddUser} />
      <Route path="/users" exact component={user} />
      <Route path="/view/:id" exact component={Viewuser} />
      <Route path="/edit/:id" exact component={Edituser} />
      <Route path="/class" exact component={Class} />
      <Route path="/academic" exact component={Academic} />
      <Route path="/subject" exact component={Subject} />
      <Route path="/school" exact component={School} />
      <Route path="/addstudent" exact component={AddStudents} />
      <Route path="/admitstudent" exact component={AdmitStudent} />
      <Route path="/currentadmissionlist" exact component={AdmissionList} />
      <Route path="/editStudent/:id" exact component={EditStudent} />
      <Route path="/demo" exact component={Demo} />


    </Router>


  );
}

export default App;
