import { Image } from "react-bootstrap";
import { useEffect, useState } from "react";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import book from "../images/book.jpg";
import { useParams } from 'react-router-dom';
import setting from "../setting.json";

const Viewuser = () => {
    const [user, setUser] = useState('');
    const { id } = useParams();
    const getUser = async () => {

        await fetch(setting.api + "/api/getUser?id=" + id)
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    // console.log(data);
                    setUser(data.data);

                }
            );
    }
    useEffect(() => {
        getUser();

    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Profile</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">Users</li>
                            <li className="breadcrumb-item active">Profile</li>
                        </ol>
                    </nav>
                </div>

                <section className="section profile">
                    <div className="row">
                        <div className="col-xl-4">

                            <div className="card">
                                <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">

                                    <Image src={book} alt="Profile" className="rounded-circle" />
                                    <h2>Kilvish Anderson</h2>
                                    <h3>Web Designer</h3>
                                    <div className="social-links mt-2">
                                        <a href="#" className="twitter"><i className="bi bi-twitter"></i></a>
                                        <a href="#" className="facebook"><i className="bi bi-facebook"></i></a>
                                        <a href="#" className="instagram"><i className="bi bi-instagram"></i></a>
                                        <a href="#" className="linkedin"><i className="bi bi-linkedin"></i></a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div className="col-xl-8">

                            <div className="card">
                                <div className="card-body pt-3">

                                    <ul className="nav nav-tabs nav-tabs-bordered">

                                        <li className="nav-item">
                                            <button className="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview">Overview</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content pt-2">

                                        <div className="tab-pane fade show active profile-overview" id="profile-overview">
                                            <h5 className="card-title">About</h5>
                                            <p className="small fst-italic">Sunt est soluta temporibus accusantium neque nam maiores cumque temporibus. Tempora libero non est unde veniam est qui dolor. Ut sunt iure rerum quae quisquam autem eveniet perspiciatis odit. Fuga sequi sed ea saepe at unde.</p>

                                            <h5 className="card-title">Profile Details</h5>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label ">Frist Name :</div>
                                                <div className="col-lg-9 col-md-8">{user.fname}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Last Name :</div>
                                                <div className="col-lg-9 col-md-8">{user.lname}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Email :</div>
                                                <div className="col-lg-9 col-md-8">{user.email}</div>
                                            </div>

                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label">Phone :</div>
                                                <div className="col-lg-9 col-md-8">{user.phone}</div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </section>

            </main>
            <Footer />
        </>
    );
}
export default Viewuser;
