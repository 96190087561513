import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Tabs, Tab, Row, Form, Col, Card, Button } from "react-bootstrap";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";

import { useEffect } from 'react';
import setting from "../setting.json";


const schema = yup.object().shape({
    name: yup.string().required("Fill value"),
    address: yup.string().required("Enter the Address"),
    state: yup.string().required("Enter the state"),
    country: yup.string().required("Enter the value"),
    city: yup.string().required("Enter the value"),
    affiliation: yup.string().required("Enter the value"),
    ownername: yup.string().required("Enter the value"),
    pin: yup.number().required("Enter the value"),
    ownerphone: yup.number().required("Enter the value"),
    email: yup.string().required("Enter the value"),
    owneraddress: yup.string().required("Enter the value"),
    schoolname: yup.string().required("Enter the value"),
    registration: yup.string().required("Enter the value"),
    schoolphone: yup.number().required("Enter the value"),
    schoolemail: yup.string().required("Enter the value"),
    schooladdress: yup.string().required("Enter the value")

});

const School = () => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    })

    const getSchoolProfile = async () => {
        await fetch(setting.api + "/api/getSchoolProfile")
            .then(response => response.json())
            .then(dd => {
                reset({
                    name: dd.name,
                    address: dd.address,
                    country: dd.country,
                    state: dd.state,
                    city: dd.city,
                    affiliation: dd.affilliation,
                    pin: dd.pin,
                    ownername: dd.owner_name,
                    ownerphone: dd.owner_phone,
                    email: dd.owner_email,
                    owneraddress: dd.owner_address,
                    schoolname: dd.school_name,
                    registration: dd.school_reg,
                    schoolphone: dd.school_phone,
                    schoolemail: dd.school_email,
                    schooladdress: dd.school_address,
                })
            }
            );

    }


    const UpdateSchool = async (dd) => {

        var ah = {
            name: dd.name,
            address: dd.address,
            country: dd.country,
            state: dd.state,
            city: dd.city,
            affiliation: dd.affilliation,
            pin: dd.pin,
            ownername: dd.owner_name,
            ownerphone: dd.owner_phone,
            email: dd.owner_email,
            owneraddress: dd.owner_address,
            schoolname: dd.school_name,
            registration: dd.school_reg,
            schoolphone: dd.school_phone,
            schoolemail: dd.school_email,
            schooladdress: dd.school_address,
        }
        await fetch(setting.api + "/api/updateSchoolProfile",
            {
                body: JSON.stringify(ah),
                method: "post",

            })
            .then(
                response => response.json()
            )
    }


    useEffect(() => {
        getSchoolProfile();
    }, []);

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>School</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>

                <Tabs
                    defaultActiveKey="profile"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    {/*  <Tab eventKey="home" title="Home">
                        Tab content for Home
                    </Tab> */}
                    <Tab eventKey="profile" title="Profile">

                        <Card body>
                            <Card title="title"></Card>
                            <Form onSubmit={handleSubmit(UpdateSchool)}>
                                <Row>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Name </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="name"
                                                placeholder='Enter...'
                                                {...register("name")}
                                            />
                                            {errors.name && <p>{errors.name.message}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Address </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="address"
                                                placeholder='Enter...'
                                                {...register("address")}
                                            />
                                            {errors.address && <p>{errors.address.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Country </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="country"
                                                placeholder='Enter...'
                                                {...register("country")}
                                            />
                                            {errors.country && <p>{errors.country.message}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>State </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="state"
                                                placeholder='Enter...'
                                                {...register("state")}
                                            />
                                            {errors.state && <p>{errors.state.message}</p>}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>City </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="city"
                                                placeholder='Enter...'
                                                {...register("city")}
                                            />
                                            {errors.city && <p>{errors.city.message}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Pin</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="pin"
                                                placeholder='Enter...'
                                                {...register("pin")}
                                            />
                                            {errors.pin && <p>{errors.pin.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>
                                        <Form.Group >
                                            <Form.Label>Affiliation</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="affiliation"
                                                placeholder='Enter...'

                                                {...register("affiliation")}
                                            />
                                            {errors.affiliation && <p>{errors.affiliation.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Owner Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="name"
                                                placeholder='Enter...'
                                                {...register("ownername")}
                                            />
                                            {errors.ownername && <p>{errors.ownername.message}</p>}
                                        </Form.Group>

                                    </Col>
                                </Row> <Row>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Owner Phone</Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                placeholder='Enter...'
                                                {...register("ownerphone")}
                                            />
                                            {errors.ownerphone && <p>{errors.ownerphone.message}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Owner Email </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="email"
                                                placeholder='Enter...'
                                                {...register("email")}
                                            />
                                            {errors.email && <p>{errors.email.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>Owner Address </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="owneraddress"
                                                placeholder='Enter...'
                                                {...register("owneraddress")}
                                            />
                                            {errors.owneraddress && <p>{errors.owneraddress.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>School Name </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="schoolname"
                                                placeholder='Enter...'
                                                {...register("schoolname")}
                                            />
                                            {errors.schoolname && <p>{errors.schoolname.message}</p>}
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>School Registration No </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="registration"
                                                placeholder='Enter...'
                                                {...register("registration")}
                                            />
                                            {errors.registration && <p>{errors.registration.message}</p>}
                                        </Form.Group>
                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>School Phone </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="schoolphone"
                                                placeholder='Enter...'
                                                {...register("schoolphone")}
                                            />
                                            {errors.schoolphone && <p>{errors.schoolphone.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>School Email </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="name"
                                                placeholder='Enter...'
                                                {...register("schoolemail")}
                                            />
                                            {errors.schoolemail && <p>{errors.schoolemail.message}</p>}
                                        </Form.Group>

                                    </Col>
                                    <Col>

                                        <Form.Group >
                                            <Form.Label>School Address </Form.Label>
                                            <Form.Control
                                                type="text"
                                                size="sm"
                                                id="schooladdress"
                                                placeholder='Enter...'
                                                {...register("schooladdress")}
                                            />
                                            {errors.schooladdress && <p>{errors.schooladdress.message}</p>}
                                        </Form.Group>

                                    </Col>
                                </Row>
                                <Button type="Submit" variant="primary" size="sm" id="sub">Update</Button>
                            </Form>
                        </Card>

                    </Tab>
                </Tabs>


            </main >
            <Footer />
        </>
    );

}
export default School;