import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { Card, Row, Col, Container, Form, Button } from "react-bootstrap";
import setting from "../setting.json";
import { useEffect, useState } from "react";
// import Select from 'react-select';
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const schema = yup.object().shape({

    clas: yup.string().required("Fill value"),
    section: yup.string().required("Fill value"),
    stu_id: yup.string().required("Fill value"),

});

const AdmitStudent = () => {

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),

    })

    const [list, setList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [classes, setClasses] = useState([]);
    const [sectionlist, setSectionlist] = useState([]);
    const [studentInfo, setStudentInfo] = useState([]);

    const updateStudent = async (data) => {
        console.log(data);
        let ss = {
            "stu_id": data.stu_id,
            "class_id": data.clas,
            "section_id": data.section,
        }
        await fetch(setting.api + "/api/saveAdmitStudent",
            {
                body: JSON.stringify(ss),
                method: "POST",
                mode: "cors",
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(response => response.json())
            .then((rec) => console.log(rec));

    }

    const getAllStudent = async () => {
        await fetch(setting.api + "/api/getAllStudent", {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(u => {
                // setSearchList(u.data);
                // console.log(u);
                // var rr = [];
                // var students = u.data;
                // students.map((rec) => {
                //     rr.push({ value: rec.ID, label: rec.stu_fname + " " + rec.stu_lname });
                // });

                setList(u.data);

            }
            );
    }

    const getAllClass = async () => {
        await fetch(setting.api + '/api/getAllClass', {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(dd => {
                setClasses(dd.data);
                //console.log(dd);
            }
            );

    }

    const getAllSection = async () => {
        await fetch(setting.api + '/api/getAllSection', {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(dd => {
                setSectionlist(dd.data);

            }
            );
    }

    useEffect(() => {
        getAllStudent();
        getAllClass();
        getAllSection();
    }, []);

    const searchStudent = async (e) => {

        var id = e.target.value;
        console.log(id);
        console.log(searchList);
        var rec;
        list.filter((u) => {
            if (u.ID == id) {
                rec = u;

            }
        });
        console.log(rec);
        setStudentInfo(rec);

    }

    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Admitstudent</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item active">Page</li>
                        </ol>
                    </nav>
                </div>

                <section>
                    <Form onSubmit={handleSubmit(updateStudent)}>
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card">
                                    <div className="card-body">
                                        <Card title="title"></Card>
                                        <Form.Group>
                                            <Form.Label >Select Student</Form.Label>
                                            <Form.Select aria-label="Default select example" {...register("stu_id")} onChange={(e) => searchStudent(e)}
                                            >
                                                {
                                                    list && list.map((a) =>
                                                        <option key={a.ID} value={a.ID}>{a.stu_fname}</option>
                                                    )
                                                }
                                            </Form.Select>
                                            {errors.stu_id && <p>{errors.stu_id.message}</p>}
                                            {/* <Form.Label >Select Student</Form.Label>
                                            <Select
                                                onChange={(e) => searchStudent(e)}
                                                options={list}
                                                name="stu_id"

                                            /> */}
                                        </Form.Group>

                                    </div>

                                </div>
                            </div>
                            <div className="col-lg-8">

                                <div className="card">
                                    <Card title="title"></Card>
                                    <div className="card-body">
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Father</Form.Label>
                                                    <Form.Control type="text" size="sm" value={studentInfo.stu_father_fname + ' ' + studentInfo.stu_father_mname + ' ' + studentInfo.stu_father_lname}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Mother</Form.Label>
                                                    <Form.Control type="text" size="sm" value={studentInfo.stu_mother_fname + ' ' + studentInfo.stu_mother_mname + ' ' + studentInfo.stu_mother_lname} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Addhar</Form.Label>
                                                    <Form.Control type="number" size="sm" value={studentInfo.stu_adhar_no} />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Date Of Birth</Form.Label>
                                                    <Form.Control type="date" size="sm" placeholder="DOB" value={studentInfo.stu_dob} />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Class</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("clas")} id="clas">
                                                        {
                                                            classes && classes.map((a) =>
                                                                <option key={a.id} value={a.id}>{a.name}</option>
                                                            )
                                                        }

                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>Section</Form.Label>
                                                    <Form.Select aria-label="Default select example" {...register("section")} id="sec"
                                                    >
                                                        {
                                                            sectionlist && sectionlist.map((a) =>
                                                                <option key={a.id} value={a.id}>{a.name}</option>
                                                            )
                                                        }
                                                    </Form.Select>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        {/* <input type='hidden' id="sid" value={id} {...register("id")} /> */}
                                        <Button type="Submit" variant="primary" size="sm" id="sub">Submit</Button>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </Form>
                </section>

            </main >
            <Footer />
        </>
    )
}
export default AdmitStudent;