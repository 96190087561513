
import React from "react";
import { useForm } from 'react-hook-form';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { Table, Button, Form, Col } from "react-bootstrap";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import { useState, useEffect } from "react";
import setting from "../setting.json";

const schema = yup.object().shape({
    subject: yup.string().required(),
    code: yup.string().required(),
    clas: yup.string().required(),
    section: yup.string().required(),
});

const Subject = () => {

    const [list, setList] = useState([]);
    const [data, setData] = useState([]);
    const [add, setAdd] = useState([]);
    const [Updated, setUpdated] = useState(false);



    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(5);

    const [classes, setClasses] = useState([]);
    const [sectionlist, setSectionlist] = useState([]);

    const [id, setId] = useState([]);
    const [subject, setSubject] = useState([]);
    const [code, setCode] = useState([]);
    const [section, setSection] = useState([]);
    const [clas, setClas] = useState([]);

    const [rec, setRec] = useState(false);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            subject: subject,
            code: code,
            class: clas,
            section: section,
        },
        rec
    })

    const saveSubject = (data) => {

        let as = new FormData();
        as.append('subject', data.subject);
        as.append('code', data.code);
        as.append('class', data.clas);
        as.append('section', data.section);
        //console.log(as);
        fetch(setting.api + "/api/addSubject",
            {
                body: as,
                method: "post",
                headers: {
                    // "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },
            })
            .then(
                response => response.json()
            )
            .then(
                (data) => {
                    if (data.status === 'success') {
                        setUpdated(!Updated);
                        document.getElementById("subject").value = '';
                        document.getElementById("code").value = '';
                        document.getElementById("clas").value = '';
                        document.getElementById("sec").value = '';

                    }
                }
            );
    }


    const getSubjectById = () => {
        var id = event.target.getAttribute("data");
        setSubject('');
        setCode('');
        setClas('');
        setSection('');
        setId('');
        //  console.log(id);
        setAdd(false);
        data.filter((a) => {
            if (a.id == id)
            //console.log(id);
            {
                setSubject(a.subject);
                setCode(a.code);
                setClas(a.classId);
                setSection(a.sectionId);
                setId(a.id);
                setRec(a);
                reset(formValues => ({
                    ...formValues,
                    subject: a.subject,
                    code: a.code,
                    class: a.clas,
                    section: a.sec,
                }))


            }

        })

    }
    const updateSubject = async (data) => {
        let as = new FormData();
        as.append('id', data.id);
        as.append('subject', data.subject);
        as.append('code', data.code);
        as.append('class', data.clas);
        as.append('section', data.section);

        console.log(as);

        await fetch(setting.api + "/api/updateSubject",
            {
                body: as,
                method: "post",
                headers: {
                    'Accept': 'application/FormData',
                    "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
                },

            })
            .then(
                response => response.json()
            )

            .then(
                (list) => {

                    setUpdated(!Updated);
                    reset(formValues => ({
                        ...formValues,
                        subject: '',
                        code: '',
                        clas: '',
                        sec: '',
                    }))
                    setAdd(true);
                }
            );
    }


    const getAllSubject = async () => {
        await fetch(setting.api + '/api/getAllSubject', {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },

        })
            .then(response => response.json())
            .then(sd => {
                setList(sd.data);
                // console.log(sd);
            }
            );

    }

    const getAllClass = async () => {
        await fetch(setting.api + '/api/getAllClass', {

            method: "GET",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(dd => {
                setClasses(dd.data);
                //console.log(dd);
            }
            );

    }


    const getAllSection = async () => {
        await fetch(setting.api + '/api/getAllSection', {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response.json())
            .then(dd => {
                setSectionlist(dd.data);

            }
            );
    }


    const deleteSubject = async () => {
        var id = event.target.getAttribute("data");
        await fetch(setting.api + '/api/deleteSubject?id=' + id, {

            method: "GET",
            mode: "cors",
            headers: {
                // "Content-Type": "application/json",
                "Authorization": 'Bearer ' + JSON.parse(localStorage.getItem("loggedUser")).token,
            },
        })
            .then(response => response)
            .then(a => {
                getAllSubject();
                // console.log(a);
            }
            );
    }


    useEffect(() => {
        getAllSubject();
        getAllClass();
        getAllSection();
    }, []);

    useEffect(() => {
        getAllSubject();

    }, [Updated]);

    useEffect(() => {
        setCurrentPage(1);
        setPageCount(Math.ceil((list.length) / pageSize));
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));
    }, [list]);


    const clickFirst = () => {
        setCurrentPage(1);
    }
    const clickPrev = () => {
        if (currentPage === 1) {
            setCurrentPage(1);
        } else {
            setCurrentPage(currentPage - 1);
        }

    }

    const clickNext = () => {
        if (currentPage === pageCount) {
            setCurrentPage(pageCount);
        } else {
            setCurrentPage(currentPage + 1);
        }
    }
    const clickLast = () => {
        setCurrentPage(pageCount);
    }

    useEffect(() => {
        var start = ((currentPage - 1) * pageSize);
        var end = (start + pageSize);
        setData(list.slice(start, end));

    }, [currentPage]);







    return (
        <>
            <Header />
            <Sidebar />
            <main id="main" className="main">

                <div className="pagetitle">
                    <h1>Subject</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
                            <li className="breadcrumb-item">Tables</li>
                            <li className="breadcrumb-item active">Data</li>
                        </ol>
                    </nav>
                </div>
                <section className="section">
                    <div className="row">
                        <div className="col-xl-4">
                            {add &&
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">Add</div>
                                        <Form onSubmit={handleSubmit(saveSubject)}>
                                            <Col>
                                                <Form.Group >
                                                    <Form.Label >Subject : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        {...register("subject")}
                                                        id="subject"
                                                    />
                                                    {errors.subject && <p>{errors.subject.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group >
                                                    <Form.Label >Code : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        {...register("code")}
                                                        id="code"
                                                    />
                                                    {errors.code && <p>{errors.code.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Label id='gro'>Class : </Form.Label>
                                                <Form.Select aria-label="Default select example" {...register("clas")} id="clas">
                                                    {
                                                        classes && classes.map((a) =>
                                                            <option key={a.id} value={a.id}>{a.name}</option>
                                                        )
                                                    }

                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label id='gro'>Section : </Form.Label>
                                                <Form.Select aria-label="Default select example" {...register("section")} id="sec"
                                                >
                                                    {
                                                        sectionlist && sectionlist.map((a) =>
                                                            <option key={a.id} value={a.id}>{a.name}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </Col>

                                            <Button type="submit" variant="success" id='sub'  >Add</Button>

                                        </Form>
                                    </div>

                                </div>
                            }
                            {!add &&
                                < div className="card">
                                    <div className="card-body">
                                        <div className="card-title"> Edit </div>
                                        <Form onSubmit={handleSubmit(updateSubject)}>
                                            <Col>
                                                <Form.Group >
                                                    <Form.Label >Subject : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        {...register("subject")}
                                                        id="subject"
                                                    />
                                                    {errors.subject && <p>{errors.subject.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col >
                                                <Form.Group >
                                                    <Form.Label >Code : </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        placeholder='Enter...'
                                                        {...register("code")}
                                                        id="code"
                                                    />
                                                    {errors.code && <p>{errors.code.message}</p>}
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Label id='gro'>Class : </Form.Label>
                                                <Form.Select aria-label="Default select example" {...register("clas")} id="clas"
                                                >
                                                    {
                                                        classes && classes.map((a) =>
                                                            <option key={a.id} value={a.id}>{a.name}</option>
                                                        )
                                                    }

                                                </Form.Select>
                                            </Col>
                                            <Col>
                                                <Form.Label id='gro'>Section : </Form.Label>
                                                <Form.Select aria-label="Default select example" {...register("section")} id="sec"
                                                >
                                                    {
                                                        sectionlist && sectionlist.map((a) =>
                                                            <option key={a.id} value={a.id}>{a.name}</option>
                                                        )
                                                    }
                                                </Form.Select>
                                            </Col>
                                            <input type='hidden' id="sid" value={id} {...register("id")} />
                                            <Button type="submit" variant="success" id='sub'  >Update</Button>

                                        </Form>
                                    </div>

                                </div>
                            }
                        </div>
                        <div className="col-lg-8">
                            {
                                <div className="card">
                                    <div className="card-body">
                                        <div className="card-title">subject List</div>
                                        <Table striped hover variant="">
                                            <thead>
                                                <tr>
                                                    <th>Sr.No.</th>
                                                    <th>Subject</th>
                                                    <th>Code</th>
                                                    <th>Class</th>
                                                    <th>Section</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data && data.map((a, i) =>
                                                        <tr key={a.id}>
                                                            <td>{i + 1}</td>
                                                            <td>{a.subject}</td>
                                                            <td>{a.code}</td>
                                                            <td>{a.class}</td>
                                                            <td>{a.section}</td>
                                                            <td> <Button variant='primary' size="sm" className="bi bi-pencil-square" data={a.id} onClick={() => getSubjectById()}></Button>
                                                                {' '}
                                                                <Button variant='danger' className='ri-delete-bin-line' size='sm' data={a.id} onClick={() => deleteSubject()}> </Button>
                                                            </td>
                                                        </tr>
                                                    )

                                                }
                                            </tbody>
                                        </Table>
                                        <div className='page' id='sub'>

                                            <Button variant='primary' className='btn' size="sm" onClick={clickFirst}>First</Button>{' '}
                                            <Button variant='primary' className='btn' size="sm" onClick={clickPrev}>Prev</Button>
                                            <span >page {currentPage} of {pageCount}</span>
                                            <Button variant='primary' className='btn' size="sm" onClick={clickNext}>Next</Button>{' '}
                                            <Button variant='primary' className='btn' size="sm" onClick={clickLast}>Last</Button>

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </section>
            </main >
            <Footer />
        </>
    );
}
export default Subject;